<template>
  <v-app :class="{ widget: $appConfig.widget }">
    <v-app-bar v-if="!($appConfig.widget)" app>
      <v-img
        :src="$vuetify.theme.dark ? $appConfig.customerLogoDarkUri : $appConfig.customerLogoUri"
        :max-width="$appConfig.customerLogoWidth + 'px'"
        :max-height="$appConfig.customerLogoHeight + 'px'"
      ></v-img>

      <div v-if="authState && authState.isAuthenticated && profile && profile.name" class="mx-5">
        {{ profile.name }}
      </div>

      <v-spacer></v-spacer>
      <v-switch :label="$gettext('Dark')" v-model="isDark" hide-details="true" class="mx-3" id="cv_dark_switch"/>

      <!-- only display logout button if we are logged in -->
      <v-btn
        id="cv_logout_button"
        v-if="authState && authState.isAuthenticated"
        color="secondary"
        :loading="logoutProcessing"
        @click="logoutSession('caller-verify-logout')"
      >
        <translate>Logout</translate>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view :settings=settings></router-view>
    </v-main>

    <CallerVerifyFooter
      v-if="!($appConfig.widget)"
      :settings="settings"
      :profile="profile"
    />

    <!-- overlay to prevent interacting with the application while loading etc. -->
    <v-overlay :value="overlay" opacity="1">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- session expiry overlay -->
    <v-overlay :value="sessionOverlay">
      <v-card color="secondary" :width="calculatedWidth">
        <v-card-title v-translate>Session Expiring Soon</v-card-title>
        <v-card-text>
          <translate>Your session will expire in less than 5 minutes.</translate><br/>
          <translate>Please click on "Refresh" below to stay logged in.</translate>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="refreshTokenRedirect()"
            color="primary"
          >
            <translate>Refresh</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

  </v-app>
</template>

<script>
import {isOAuthError, isRefreshTokenInvalidError} from '@okta/okta-auth-js';
import {translate} from 'vue-gettext';

import CallerVerifyFooter from './components/CallerVerifyFooter.vue';
const {gettext: $gettext} = translate;

export default {
  name: 'app',

  components: {
    CallerVerifyFooter
},

  data: () => ({
    appInitialized: false,
    logoutProcessing: false,
    sessionOverlay: false,
    sessionWarningLimit: 5,
    expiredRefreshToken: false,

    profile: null,
    settings: {},

    gradientStart: '#A60000',
    gradientEnd: '#590000',

  }),
  computed: {
    isDark: {
      get: function() {
        return this.$vuetify.theme.dark
      },
      set: function(value) {
        // set the light/dark theme based on what the value.
        this.$vuetify.theme.dark = value;

        // keep track of current setting for theme in a cookie.
        if (this.$vuetify.theme.dark) {
          this.$cookies.set('verifyIsDark', true);
        } else {
          this.$cookies.remove('verifyIsDark');
        }
      }
    },
    calculatedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100%'
        default: return '400px'
      }
    },
    overlay() {
      return (!((this.$route.path === '/') || (this.$route.path === '/error') || (this.authState && this.authState.isAuthenticated)));
    }
  },
  watch: {
    'authState.isAuthenticated': async function(isAuthenticated) {

      // auth state says authenticated, but we also have to make sure our tokens are not pending removal.
      if(isAuthenticated) {
        this.$auth.tokenManager.getTokens().then((tokens) => {
          // if the ID and Access tokens are not pending removal, then we can redirect to the /verify page.
          if(!tokens.idToken.pendingRemove && !tokens.accessToken.pendingRemove) {
            // CLAIM: if we are here, then we are really authenticated and not about to be logged out.
            this.$auth.getUser().then((profile) => {
              this.profile = profile;
            });

            // initialize timers to display session expiry warning based on Okta session.
            this.setupSessionExpiryTimers();

            // Save the current access token for authenticated API requests
            this.$ajax.defaults.headers.common['Authorization'] = `Bearer ${this.$auth.getAccessToken()}`;

            this.$ajax.get('/api/v1/config/settings').then((response) => {
              if(response && (response.status === 200) && response.data && response.data.config) {
                this.settings = response.data.config;
                // override the selected attr with the widget config (if specified)
                if(this.$appConfig.lookupField) {
                  this.settings.selectedAttr = this.$appConfig.lookupField;
                }
              }
              else {
                // log error and load defaults.
                console.error("Failed to load application config, using defaults.");
                this.loadDefaultSettings();
              }
            })
            .catch((error) => {
              console.error("Failed to load application config (" + error + "), using defaults.");
              this.loadDefaultSettings();
            });
          }
        });
      }
    }
  },
  methods: {
    logoutSession: function(state) {
      this.logoutProcessing = true;

      if (state !== undefined) {
        this.$auth.signOut({state: state});
      } else {
        this.$auth.signOut();
      }
    },
    applyTheme: function(appTheme) {

      // configure login gradient theme colors.
      if (appTheme.loginGradient) {
        const loginGradient = appTheme.loginGradient;
        if (loginGradient.start) {
          this.gradientStart = `#${loginGradient.start}`;
        }
        if (loginGradient.end) {
          this.gradientEnd = `#${loginGradient.end}`;
        }
      }

      // configure the light theme colors.
      if (appTheme.light) {
        Object.entries(appTheme.light).forEach(entry => {
          const [key, color] = entry;

          if (this.$vuetify.theme.themes.light[key] && color) {
            this.$vuetify.theme.themes.light[key] = `#${color}`;
          } else {
            console.log(`No "${key}" color for light theme.`);
          }
        });
      }

      // configure the dark theme colors.
      if (appTheme.dark) {
        Object.entries(appTheme.dark).forEach(entry => {
          const [key, color] = entry;

          if (this.$vuetify.theme.themes.dark[key] && color) {
            this.$vuetify.theme.themes.dark[key] = `#${color}`;
          } else {
            console.log(`No "${key}" color for dark theme.`);
          }
        });
      }

      // Initialize the app
      this.appInitialized = true;
    },
    loadDefaultSettings() {
      this.settings = {
        'featureFlags': {
          'disableUnlock': true,
          'disableFactorResetSingle': true,
          'disableFactorResetAll': true,
          'enableFactorEnrollment': false,
          'enableLogDetails': false,
          'obfuscateMFACredIDs': true,
          'quickVerifyFactor': '',
          'deviceFlowDeliverySMS': false,
          'deviceFlowDeliveryEmail': false,
          'displayWeblinkMode':'url',
        },
        'logDeltaHours': 24,
        'attributes': [
          {
            "text": "Login (username)",
            "value": "profile.login"
          },
          {
            "text": "Primary email address",
            "value": "profile.email"
          },
          {
            "text": "Primary phone number",
            "value": "profile.primaryPhone"
          },
          {
            "text": "First and last name",
            "value": "firstAndLastName"
          }
        ],
        'selectedAttr': 'profile.email'
      };
    },
    refreshTokenRedirect() {
      // perform a authorization code flow w/PKCE again to refresh our access & id-token.
      //
      // DOC: This requires 3rd party cookies to make this seamless when the okta session
      // is still active.
      this.$auth.signInWithRedirect({originalUri: '/verify'});
    },
    registerTokenRefreshCB() {
      // Triggered when a token has been renewed
      this.$auth.tokenManager.on('renewed', this.tokenRefreshCB);

      // Triggered when an OAuthError is returned via the API (typically during token renew)
      this.$auth.tokenManager.on('error', this.errorCaptured);
    },
    tokenRefreshCB(key, newToken, /* oldToken */) {
      if(key === 'accessToken') {
        this.$ajax.defaults.headers.common['Authorization'] = `Bearer ${newToken.accessToken}`
      }
      // if we errored out before with an expired refresh tokeh, and we got back here.. then our
      // new tokens were transparently refreshed, clear the error flag and ensure we end up back
      // on /verify.
      if(this.expiredRefreshToken) {
        this.expiredRefreshToken = false;
        this.$router.push('/verify');
      }
    },
    setupSessionExpiryTimers() {
      // For now we tie our CallerVerify session to the Okta session.
      //
      // Once Authenticated, we need to query the okta session to get
      // its expiry details.
      //
      this.$auth.session.get().then((session) => {
        // get the session expiry, and setup a timer to warn the user of session expiry.
        if (session.expiresAt) {
          const sessionExpiry = new Date(session.expiresAt);
          const diffMS = sessionExpiry - new Date();
          const diffMinutes = diffMS / 1000 / 60;

          // No time left in session, logout the user
          if (diffMinutes <= 0) {
            console.debug('Session has expired, logging out.');
            this.logoutSession('caller-verify-session-logout');
          }
          else if (diffMinutes <= this.sessionWarningLimit) {
            // Show session expiry warning with "refresh"
            this.sessionOverlay = true;

            // Set a timer to log the user out
            setTimeout(this.logoutSession, 60000 * diffMinutes, 'caller-verify-session-logout');
          }
          else {
            // Set a timer to "expiry - (warning limit)" to show expiry
            const warningMS = diffMS - (60000 * this.sessionWarningLimit);

            setTimeout(() => {
              // Show session expiry warning with "refresh"
              this.sessionOverlay = true;

              // Set a timer to log the user out
              setTimeout(this.logoutSession, 60000 * this.sessionWarningLimit, 'caller-verify-session-logout');
            }, warningMS);
          }
        }
        else {
          // No session expiry found, return to login screen
          console.debug('No session, redirecting to login page.');
          this.logoutSession('caller-verify-session-logout');
        }
      }).catch(() => {
        // Log the error
        console.error($gettext('Either third-party cookies are blocked, or no session found.'));
      });
    },
    // Handle OAuth Flow Errors reported by OktaVue's callback.
    errorCaptured(error) {
      if(isRefreshTokenInvalidError(error)) {
        // refresh token has expired, but we might still have an okta session.  The background
          // refresh token has expired, but we might still have an okta session.  The background
        // refresh token has expired, but we might still have an okta session.  The background
        // AuthJS service will hit the authorize endpoint with prompt=none to transparently get a
        // new token.  If this works we can restore our session transparently, if not then we will
          // new token.  If this works we can restore our session transparently, if not then we will
        // new token.  If this works we can restore our session transparently, if not then we will
        // error out.
        this.expiredRefreshToken = true;
        return;
      }
      else if(error.error == 'login_required') {
        return;
      }
      // only display oauth errors that we don't handle to the user.
      else if(isOAuthError(error)) {
        // can't handle this error, display a message to the end user.
        this.$router.push({ path: '/error', query: {error: error.name, error_description: error.errorSummary}});
      }
    }
  },
  // apply the customer's theme / configuration to the application.
  mounted() {
    // Check for the dark theme cookie
    if (this.$cookies.get('verifyIsDark')) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }

    if(!this.$appConfig.initializeError) {
      this.registerTokenRefreshCB();
      this.$auth.options.onAuthError = this.errorCaptured;
      this.$auth.options.onAuthRequired = () => {
        // auth required, send the user to the login page.
        this.$router.push('/');
      }
    }

    // Applying theme must be last
    if (this.$appConfig.appTheme) {
      this.applyTheme(this.$appConfig.appTheme);

      // Allow apply theme to initialize app
      return;
    }

    this.appInitialized = true;
  }
}
</script>

<style scoped lang="scss">
  div.widget > ::v-deep div.v-application--wrap {
    min-height: fit-content;
  }
</style>
