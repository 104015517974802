<template>
  <v-container fluid fill-height id="login-container" :style="cssVars">
    <!-- if we are not in widget mode (or there is an initialization error), then render the regular login page -->
    <v-card v-if="!$appConfig.widget || $appConfig.initializeError" class="mx-auto pa-5 elevation-5">
      <v-card-title>
        <v-img
          :src="$vuetify.theme.dark ? $appConfig.customerLogoDarkUri : $appConfig.customerLogoUri"
          :max-width="$appConfig.customerLogoWidth + 'px'"
          :max-height="$appConfig.customerLogoHeight + 'px'"
          class="ma-2"
        ></v-img>
        <div class="text-h3 py-3">
          Caller Verify
        </div>
      </v-card-title>
      <v-card-text v-if="!$appConfig.initializeError" class="text-h6">
        <translate>Welcome to the %{ $appConfig.customer } Caller Verify application.</translate>
        <br/>
        <translate>Sign in to begin verifying your callers.</translate>
        <div class="mt-4">
          <v-img src="~@/assets/img/caller-verify-trio.png" max-width="400px" class="mx-auto"></v-img>
        </div>

        <v-alert v-if="logoutSuccess" type="info" dense class="mt-4 mb-0">
          <translate>{{ logoutMessage }}</translate>
        </v-alert>
      </v-card-text>
      <v-card-text v-if="$appConfig.initializeError" class="text-h6">
        <v-alert
          color="error"
          type="error"
        >
          <translate>There was an error initializing Caller Verify.</translate>
          <br/>
          <translate>Please contact TechJutsu support for assistance.</translate>
        </v-alert>
      </v-card-text>
      <!-- only display login button if we initialized okay and we are not in widget mode -->
      <v-card-actions v-if="!$appConfig.initializeError && !$appConfig.widget" class="justify-center">
        <v-btn large color="primary" class="ma-2"
          id="cv_login_button"
          :loading="loginLoading"
          @click="login()"
        >
          <translate>Login</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- widget mode and no initialize error, display the Custom Hosted SIW -->
    <div v-if="$appConfig.widget && !$appConfig.initializeError" class="mx-auto">
      <div id="okta-signin-container"></div>
    </div>
  </v-container>
</template>

<script>
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

import OktaSignIn from '@okta/okta-signin-widget'

export default {
  name: 'LoginPage',

  data: () => ({
    logoutSuccess: false,
    logoutMessage: 'You have been successfully logged out of Caller Verify.',
    loginLoading: false,
    // okta SIW (if we are in widget mode)
    widget: undefined
  }),
  computed: {
    cssVars() {
      return {
        '--gradient-start-color': `#${this.$appConfig.appTheme.loginGradient.start}`,
        '--gradient-end-color': `#${this.$appConfig.appTheme.loginGradient.end}`
      }
    },
  },
  methods: {
    login() {
      this.loginLoading = true
      this.$auth.signInWithRedirect({originalUri: '/verify'})
    }
  },
  mounted() {
    // Retrieve the browser query string for logout message
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    if (state === 'caller-verify-session-logout') {
      this.logoutSuccess = true;
      this.logoutMessage = 'You have been logged out of Caller Verify. No session found or your session has expired.';
    } else if (state === 'caller-verify-logout') {
      this.logoutSuccess = true;
    }

    // prepare the widget object if we might need it for login.
    if(this.$appConfig.widget && !this.$appConfig.initializeError) {
      this.widget = new OktaSignIn({
        baseUrl: this.$auth.options.issuer.split('/oauth2')[0],
        clientId: this.$auth.options.clientId,
        redirectUri: this.$auth.options.redirectUri,
        // if we are talking to an OIE backend enable interaction code flow.
        useInteractionCodeFlow: this.$appConfig.idp.version === 'oie',
        authParams: {
          issuer: this.$auth.options.issuer,
          scopes: this.$auth.options.scopes,
        },
        logo: this.$appConfig.customerLogoUri,
        i18n: {
          'en': {
            'primaryauth.title': 'Sign in to ' + this.$appConfig.customer  +  ' Caller Verify'
          },
        }
      });
    }

    // if the caller is already authenticated and the tokens are not pending removal,
    // then just dump them on the /verify link.
    this.$auth.isAuthenticated().then( async (isAuthenticated) => {
      if(isAuthenticated) {
        this.$auth.tokenManager.getTokens().then((tokens) => {
          // if the ID and Access tokens are not pending removal, then we can redirect to the /verify page.
          if(!tokens.idToken.pendingRemove && !tokens.accessToken.pendingRemove) {
            this.$router.push("/verify");
          }
        })
      }
      // else, we are not authenticated (or at least don't have tokens)
      // if we are in widget mode and classic okta, then we can try to
      // sliently get the tokens, for OIE this happenes automatically
      // in the SIW.
      //
      // NOTE: This requires 3rd party cookies.
      else if(this.$appConfig.widget && this.$appConfig.idp.version === 'classic') {
        try {
          let response = await this.$auth.token.getWithoutPrompt();
          this.$auth.tokenManager.setTokens(response.tokens);
          this.$router.push("/verify");
        }
        catch(err) {
          if(err.errorCode === 'login_required') {
            console.log('User not authenticated, displaying login page.');
          }
          // unexpected error, log it.
          else {
            console.error(err);
          }
          // either way display the login widget to get the user authenticated.
          this.widget.showSignInToGetTokens({
            el: '#okta-signin-container',
            scopes: this.$auth.options.scopes,
          }).then(tokens => {
            this.$auth.handleLoginRedirect(tokens);
            this.$router.push("/verify");
          }).catch(err => {
            throw err
          });
        }
      }
    });

    // if we are in widget mode and we are on Okta OIE, display
    // the widget here.  This will handle auto-login if we have
    // an existing session with Okta for OIE.  For classic we
    // handle this above.  We don't display the widget in Classic
    // mode here, otherwise we could display it before checking
    // for a session which is a bad UI expereince.
    if(this.widget &&  this.$appConfig.idp.version === 'oie') {
      this.widget.showSignInToGetTokens({
        el: '#okta-signin-container',
        scopes: this.$auth.options.scopes,
      }).then(tokens => {
        this.$auth.handleLoginRedirect(tokens);
        this.$router.push("/verify");
      }).catch(err => {
        throw err
      });
    }
  },
  destroyed() {
    if(this.$appConfig.widget && this.widget) {
      this.widget.remove();
    }
  }
}
</script>

<style scoped>
#login-container {
  background: var(--gradient-start-color);
  background: radial-gradient(circle, var(--gradient-start-color) 50%, var(--gradient-end-color) 100%);
}
</style>
